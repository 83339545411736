import Vuex from 'vuex'
import Mutations from '@/store/mutations'
import Actions from '@/store/actions'
import Getters from '@/store/getters'

export default new Vuex.Store({
  state: {
    profile:{
      client:null,
      id:'',
      username:'',
      hash:'',
      fullname:'',
      email:'',
      role_id:'',
      measure_template_id:0,
      access_tags:[]
    },
    options:{
      title:'',
      current:{
        object:'',
        build:''
      }
    },
    notifications:[],
    measure:{},
    connections:[],
    params:[],
    alerts: {
      active: [],
      waiting: [],
      history: [],
      translate:{}
    },
    ws:{
      status: false,
      client:null,
      server:null,
      temporaryMessage:null,
      volume:0,
    }
  },
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  modules: {}
})
