module.exports = {
    debug: false,
    symlink: 'dist', // "dist/public"  #production   "public" #dep
    path: __dirname,
    //path: "D:/project/scom_v2/scom_prod", //build
    project: {
        path: "scom_project_office_pmvent", //папка проекта
        indicator_ws: true,
        database:{
	    name: "scom_test",
            username: "mysql",
            password: "mysql",
            //"dialect": "sqlite",
            //"storage": "src/projects/scom_project_office_pmvent/pmvent.sqlite3",
            //"migrationStorageTableName": "sequelize_meta",
            //"logging": false,
            config:{
                "host": "git.office.pmvent.ru",
                "dialect": "mysql",
                "logging": false,
                "seederStorageTableName": "sequelize_meta"
            }
        }
    },
    metric:{
        enable: false,
        interval: 5, //seconds
        host: 'http://git.office.pmvent.ru:8086',
        //host: '10.1.5.122',
        token:'ey2sXF0pkDJ11qsv3k_vkwHGCMz35bWrR4pixzj0sW8CHo12OsgToRudcHWM5n0_bC4e6CSGiLH0nq2TvHBxBg==',
        org:'PMVent',
        bucket:'SCOM'//корзина =!? базаданных
    },
    server:{
        ws:{
             frontend:{
                 ssl: true,
                 host: '2d.scom-vsm.ru/ws/',
                 //port: '7575'
             },
            backend:{
                ssl: false,
                host: 'git.office.pmvent.ru',
                port: '7081'
             }
           },
        api: {
            frontend:{
                ssl: true,
                host: '2d.scom-vsm.ru',
                //port: '7576'
            },
            backend:{
                ssl: false,
                host: 'git.office.pmvent.ru',
                port: '7080'
            }
        },
        vsm_api: {
            host: 'ws://git.office.pmvent.ru:9999',
            debug: false
        },
        interval:{
            connectionsCheck: 60 //min
        }
    },
    vsm_module: {
        path: "/src/vsm" // путь до модуля VSM
    }
};
