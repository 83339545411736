/* eslint-disable */

/* ОБЪЕКТ WS
 * Примеры использования:
 * ```
 * //имитация ответа сервера из консоли браузера
 * WS.sendObj({cmd:'echo_text', data:JSON.stringify({cmd:'notify', data:{"user_panel.TempOV_20":"2360"}, target:'vsm0'})})
 *
 * //Имитация ответа сервера раз в секунду с изменением значения 9999 - 1111
 * var val, t=v=>{WS.sendObj({cmd:'echo_text', data:JSON.stringify({cmd:'notify', data:{"user_panel.TempOV_20":v}})})}, timer=setInterval(()=>{t(val=(1111==val)?9999:1111)}, 1000)
 * ```
 */


// ============== Ошибки  ============

class SingletonError extends Error {
    constructor(message) {
        super(message)
        this.name = 'SingletonError'
    }
}

// ============== Основной класс  ============

class WS {
    constructor(url = undefined) {
        if (null !== WS._single) throw new SingletonError("Can't create second WS object")
        WS._single = this
        this.reconnect = true
        this.connected = false
        this.connect(url)
    }

    connect(url) {
        this._url = ('undefined' == typeof url) ? this._url : url
        let q =  (('undefined' != typeof this._id) ?(`?id=${this._id}`) : '')

        this.ws = ('undefined' == typeof WebSocket) ? new WS.WebSocketNode(this._url + q) :  new WebSocket(this._url + q)
        const slf= this
        this.ws.onopen = () => { //this.ws.send('hey')
            this.connected = true
            if (WS.localStorage.debug) console.debug('open url', this._url + q);
            (WS.LISTENERS.get(WS.LISTENER_NAME_CONNECT) || []).forEach(listener=>{ try{listener(this.ws)}catch(e){} })
            //this.ws.send(JSON.stringify({client_say:'I am conected', d: new Date().toLocaleTimeString()}));
            //console.log('open query', q);
        }

        this.ws.onclose = event=>{
            this.connected = false
            //console.log('Код: ' + event.code + ' причина: ', event.reason, event)
            ;(WS.LISTENERS.get(WS.LISTENER_NAME_DISCONNECT) || []).forEach(listener=>{ try{listener(this.ws)}catch(e){} })
            if (this.reconnect) setTimeout(()=>{
                if (WS.DEBUG) console.log('try reconnect')
                this.connect(this._url)
            },5000)
        }
        this.ws.onerror = (error) => {
            if (WS.DEBUG) console.log('WebSocket error:', error);
            (WS.LISTENERS.get(WS.LISTENER_NAME_ERROR) || []).forEach(listener=>{ try{listener(null)}catch(e){} })
        }

        this.ws.onmessage = (e) => {
            //console.log(e);
            let json = null, text = e.data
            try{json = JSON.parse(e.data)}catch(ex){}
            if (null !== json){
                if (WS.DEBUG) console.log('JSON: ', json)
                if ('cmd' in json) {
                    (WS.LISTENERS.get(json.cmd) || []).forEach(listener=>{ try{listener(json)}catch(e){} })
                }
            }else {
                if (WS.DEBUG) console.log('TEXT: ',text)
            }
            if (WS.LISTENERS.has(WS.LISTENER_NAME_ALL)) {
                (WS.LISTENERS.get(WS.LISTENER_NAME_ALL) || []).forEach(listener=>{ try{listener(json,text)}catch(e){} })
            }
        }
    }

    /** Разъединиться с вэбсокет сервером. И поумолчанию вырубить reconnect для исключения автоматического повторного соединения */
    disconnect(reconnect = false) {
        this.setReconnect(reconnect)
        try{ this.ws.close() } catch (e) { }
    }

    /** Изменить флаг автоматического повторного соединения */
    setReconnect(reconnect) {
        this.reconnect = reconnect
    }


    get get() { return this.ws }


    /** Отправить объект в текущем соединении с сервером. Если случится ошибка отправки то при наличии WS.DEBUG она будет отправлена в console.error
     * @param {Object} obj Ex.: {cmd:"help" target:"vsm0"}
     * @return {Error|null} Если отправка прошла успешно - вернуть null; Если вызвала ошибку - вернуть её */
    sendObj(obj){
        try{this.ws.send(JSON.stringify(obj)); return null}catch (e) {if(WS.DEBUG) console.error(e);return e}
    }
    // /** @deprecated */
    // sendCmd(cmd, obj={}) { return this.sendObj({cmd, ...obj}) }
    // /** @deprecated */
    // sendData(cmd, data) { return this.sendCmd(cmd, {data}) }


    /** Привязать конкретный обработчик событию cmd
     * Ex.1: WS.on('setId',console.log)
     * Ex.2: WS.on('time', [console.log, console.error])
     * @param {string} cmd Имя события Ex.: 'setId'
     * @param {Function|Array} listener (json) Функция обработчик или их массив Ex.: [console.log, console.error] */
    on(cmd, listener){
        return WS.on(cmd, listener)
    }

    /** Отвязать конкретный обработчик события cmd
     * Ex.1: WS.on('setId',console.log); WS.off('setId',console.log)
     * Ex.2: WS.on('time', [console.log, console.error]); WS.off('setId', [console.log, console.error])
     * @param {string} cmd Имя события Ex.: 'setId'
     * @param {Function|Array} listener Функция обработчик или их массив Ex.1 msg=>alert(msg.data); Ex.2: [console.log, console.error] */
    off(cmd, listener){
        return WS.off(cmd, listener)
    }

    /** Отвязать все обработчики события cmd
     * @param {string} cmd Имя события Ex.: 'setId' */
    offAll(cmd){ return WS.offAll(cmd) }
}
// ============== УПРАВЛЕНИЕ ОБЪЕКТОМ ============


if ('undefined' == typeof localStorage) {  // если localStorage не определён - значит запущен НА СЕРВЕРЕ
    class LocalStorage extends Map {
        get debug(){return false}
    }
    WS.localStorage = new LocalStorage()
    WS.WebSocketNode = require('ws')
} else {
    WS.localStorage = localStorage
}


/** @var {boolean} */
WS.DEBUG = false

/** @var {WS} */
WS._single = null

/** Создать или вернуть объект WS
 * @param {string} URL куда коннектиться (если объект создаётся) Ex.: 'ws://localhost:8080'
 * @return {WS} */
WS.get = url=>(null !== WS._single) ? WS._single : new WS(url)

WS.hasConnected = ()=> (null !== WS._single) && WS._single.connected


// ============== СОБЫТИЯ ============

/** Слушатели ws-событий каждая функция message=>{} для connect и disconnect в message указывается null в остальных принятый объект */
WS.LISTENERS = new Map([
    [WS.LISTENER_NAME_CONNECT = 'connect', [ //обработка события соединения с сервером
        //()=>WS.sendObj({client_say:'I am conected', d: new Date().toLocaleTimeString()})
    ]],
    [WS.LISTENER_NAME_DISCONNECT = 'disconnect', []], //обработка события дисконнекта с сервером


    [WS.LISTENER_NAME_ALL = 'all-messages', [ //Обработчики получения любого сообщения от сервера ещё до обработки всех остальных команд
        //(json, text)=>{console.log('принято', text)}
    ]],
    [WS.LISTENER_NAME_ERROR = 'error', []],


    ['setId', [ //msg={cmd:'setId', data:{id:"id3434"}}
               msg=>{ if (('data' in msg) && ('id' in msg.data)) { WS.get()._id =  msg.data.id } },
    ]],
    ['id', [//{cmd:'id', data:'c10'}
            msg=>{ if (('data' in msg) && msg.data) { WS.get()._id =  msg.data } },
    ]],

])

/** Привязать конкретный обработчик событию cmd
 * Ex.1: WS.on('setId',console.log)
 * Ex.2: WS.on('time', [console.log, console.error])
 * @param {string} cmd Имя события Ex.: 'setId'
 * @param {Function|Array} listener Функция обработчик или их массив Ex.: [console.log, console.error] */
WS.on=(cmd, listener)=>{
    if(!WS.LISTENERS.has(cmd)) WS.LISTENERS.set(cmd,[])
    let listeners = (listener instanceof Function)? [listener] : ((listener instanceof Array) ? listener :[])
    if (listeners.length) {
        const arr = WS.LISTENERS.get(cmd)
        listeners.forEach(listener=>{
            if ((listener instanceof Function) && (arr.indexOf(listener) < 0)) arr.push(listener)
        })
    }
}


/** Привязать конкретный обработчик (или несколько) событию cmd и по его первому выполнению - удалить
 * Ex.1: WS.once('setId',console.log)
 * Ex.2: WS.once('time', [console.log, console.error])
 * @param {string} cmd Имя события Ex.: 'setId'
 * @param {Function|Array} listener Функция обработчик или их массив Ex.: [console.log, console.error]
 * @return {Function} функция регистрируемая для обработчика cmd. Обёртка для listener */
WS.once = (cmd, listener)=>{
    let f = msg=>{
        WS.off(cmd, f) //удалить себя
        let listeners = (listener instanceof Function)? [listener] : ((listener instanceof Array) ? listener :[])
        listeners.forEach(funct=>funct(msg)) //выполнить польз. ф-ю
    }
    WS.on(cmd, f)
    return f
}

/** Отвязать конкретный обработчик события cmd
 * Ex.1: WS.on('setId',console.log); WS.off('setId',console.log)
 * Ex.2: WS.on('time', [console.log, console.error]); WS.off('setId', [console.log, console.error])
 * @param {string} cmd Имя события Ex.: 'setId'
 * @param {Function|Array} listener Функция обработчик или их массив Ex.1 msg=>alert(msg.data); Ex.2: [console.log, console.error] */
WS.off=(cmd, listener)=>{
    WS.on(cmd)
    let listeners = (listener instanceof Function) ? [listener] : ((listener instanceof Array) ? listener : [])
    const arr = WS.LISTENERS.get(cmd)
    listeners.forEach(listener=>{
        let index = arr.indexOf(listener)
        if (index >= 0) arr.splice(index, 1)
    })
}

/** Отвязать все обработчики события cmd
 * @param {string} cmd Имя события Ex.: 'setId' */
WS.offAll=cmd=>{ if(WS.LISTENERS.has(cmd)) WS.LISTENERS.delete(cmd) }

WS.QUEUE = []
const queueCmd = 'hello'
const QUEUE_LISTENER = (msg)=>{ // msg={"cmd":"hello", "data":"c5", "session_info":{"timer":null,"vsm_subscribe":false,"ws":1,"id":"c5","sockets":{"vsm2":{},"vsm3":{}}},"started":"Tue Mar 21 2023 08:54:46 GMT+0300 (Москва, стандартное время)","time":1679378086812,"objects":["vsm2","vsm3"],"hosts":{"vsm2":"10.1.5.184:24000","vsm3":"10.1.5.109:24000"}, "date":"Wed Mar 22 2023 16:44:45 GMT+0300 (Москва, стандартное время)", "version":"1.12.4 / boost 1_74 / gcc 11.2.0 / linux / May 26 2022 / Stack: 8388608"}
    for(let obj of WS.QUEUE) {
        WS.sendObj(obj, false)
    }
    WS.off(queueCmd, QUEUE_LISTENER) // открепить обработчик по завершении очереди команд
}

    /** Отправить сообщение по текущему соединению WebSocket на основе объекта
 * ```
 * WS.sendObj({cmd:'help', data:'id'}) // отправка {cmd:'help', data:'id'}
 * WS.sendObj({cmd:'set', data:{a:'id'}}) // отправка {cmd:'set', data:{a:'id'}}
 * ```
 * @param {Object} obj Ex.: {cmd:'help', data:'id'}
 * @return {Error|null} Вернуть null или ошибку если она возникла */
WS.sendObj=(obj, queueUse=false)=>{
    if (WS.hasConnected()) return WS.get().sendObj(obj);
    else {
        if (!queueUse) return new Error(`The WebSocket is not connected. Forbidden to use a queue for deferred sending.`);
        WS.QUEUE.push(obj) //добавить команду в очередь
        if (!WS.LISTENERS.has(queueCmd)) WS.LISTENERS.set(queueCmd,[])
        if (!WS.LISTENERS.get(queueCmd).includes(QUEUE_LISTENER)) {
            WS.on(queueCmd, QUEUE_LISTENER)
        }
        return null
    }
}
WS.clearQueue=()=>{WS.QUEUE.length=0; WS.off(queueCmd, QUEUE_LISTENER)}

//
// /** Отправить сообщение по текущему соединению WebSocket используя имя команды и объект
//  * @deprecated
//  * ```
//  * WS.sendCmd('help', {data:'id'}) // отправка {cmd:'help', data:'id'}
//  * ```
//  * @param {Object} obj Ex.: {data:'id'}
//  * @return {Error|null} Вернуть null или ошибку если она возникла */
// WS.sendCmd=(cmd, obj={})=> { return WS.get().sendObj({cmd, ...obj}) }
//
// /** @deprecated */
// WS.sendData=(cmd, data)=> { return WS.get().sendCmd(cmd, {data}) }

/** @param {string} target строка с идентификаторами через запятую Ex.: 'vsm0,vsm5,vsm2'
 *  @return {Array} массив идентификаторов Ex.: ['vsm0','vsm5','vsm2'] */
const targetToArr=target=>target.split(',').filter(n=>n!='')

/** Подписка на VSM-параметры с последующей отпиской после первого получения результатов
 * @param {Array|string} params Ex.: 'modules.server.current_time' Ex.2: ['user_panel.unit_type','user_panel.unit_name']
 * @param {string} socket_id Строка идентифицирующая web-клиента (websocket) либо группу параметров
 * @param {string} target строка с идентификаторами через запятую Ex.: 'vsm0,vsm5,vsm2'
 * @return {Promise} resolve({cmd:"notify", target:"vsm0", data:{"modules.server.current_time":"10:38:12"}, id:"1620979947750.9805"}) */
WS.oncePromise = async (params, socket_id , target=undefined) => new Promise(resolve => {
    const wait_params = new Set((params instanceof Array) ? params : (params?[params]:[]))
    if (![...wait_params].filter(s=>''!=s.trim()).length) resolve({data:null})
    const listenCmd = 'notify'
    const id = (Date.now()+Math.random()).toFixed(4) //"1620979366042.4858"
    //вначале подписка
    let j = null
    let first = true
    let listener = json => {
      if (id == json.id) {
          if (null===j) j=json
          Object.keys(json.data).forEach(param=>{
              wait_params.delete(param)
              if (!first) j.data[param] = json.data[param]
          })
          first = false
          if (!wait_params.size) {
              WS.off(listenCmd, listener)
              resolve(j)
          }
      }
    }
    WS.on(listenCmd, listener)


    //затем команда
    let cmdObj = {cmd:'once', id, data:params}
    if ('undefined' != typeof target) cmdObj.target = target
    if ('undefined' != typeof socket_id) cmdObj.socket_id = socket_id
    WS.sendObj(cmdObj)
})

/** Вернуть ID соединения с хостом.
 * Промис который разрешается
 *  - успехом при соединении с указанным VSM-хостом (и возвращает его id)
 *  - либо ошибкой (с возвратом хоста), если соединениие с хостом в заданный период установить не удалось
 *  @param {string} host С чем соединяться Ex.: '10.1.5.184:24000'
 *  @param {Number|undefined} Таймаут ожидания коннекта в мс. Ex.: 20000 (20 сек)
 *  @return {Promise} resolve(id) или reject(host) Ex.: id: 'vsm0' ; host: '10.1.5.184:24000' */
WS.connectPromise = async (host, timeout = undefined, total=undefined)=>new Promise((resolve, reject)=>{
    const id = (Date.now()+Math.random()).toFixed(4) //"1620979366042.4858"
    const cmd = 'vsm_once_connect'
    let listener = json => {
        if (id == json.id) {
            WS.off(cmd, listener)
            if (json.data) resolve(json.data); else reject(json.host)
        }
    }
    WS.on(cmd, listener)
    let req = {cmd, id, data:host}
    if ('undefined' != typeof total) req.total = !!total
    if ('undefined' != typeof timeout) req.timeout = timeout
    WS.sendObj(req)
})



/** Резервирование socket_id для конкретных VSM-соединений через промис
 * ```
 * const target = 'vsm0'
 * (await WS.socketReservePromise('fake', target)).data[target] // "c0_1_fake"
 * ```
 * Если VSM-соединение запрошенное в target отсуствует среди установленных, сервером, то никакого сокета зарезервировано для него не будет и информации по нему не будет в ответе data.
 * Например: сервер имеет VSM-соединения vsm0 и vsm1 а запрашиваются WS.socketReservePromise('fake', 'vsm1,vsm2') - тогда в resolve data будет только vsm1 Ex.: resolve({cmd:"socket_reserve", data:{vsm1:"c0_fake"}, id:"1635860457275.1628"})
 *
 * @param {string} suffix чем должно завершаться имя зарезервированного сокета Ex.: 'fake'
 * @param {string} target строка с идентификаторами через запятую Ex.: 'vsm0,vsm5,vsm2'
 * @return {Promise} resolve({cmd:"socket_reserve", data:{vsm0:"c0_fake", vsm5:"c0_1_fake", vsm2:"c0_fake"}, id:"1635860457275.1628"}) */
WS.socketReservePromise = async (suffix, target=undefined) => new Promise(resolve => {
    const id = (Date.now()+Math.random()).toFixed(4) //"1620979366042.4858"
    const listenCmd = 'socket_reserve'
    //вначале подписка
    let listener = json => {
        if (id == json.id) {
            WS.off(listenCmd, listener)
            resolve(json)
        }
    }
    WS.on(listenCmd, listener)


    //затем команда
    let cmdObj = {cmd:listenCmd, id}
    if ('undefined' != typeof suffix) cmdObj.data = suffix
    if ('undefined' != typeof target) cmdObj.target = target
    WS.sendObj(cmdObj)
})



/** Однократное получение списка всех VSM-параметров либо комментов с последующей отпиской после первого получения результатов
 * @param {string} socket_id Строка идентифицирующая web-клиента (websocket) либо группу параметров
 * @param {string} data Если указана строка из неё строится выражение для фильтрации списка параметров  Ex.: '^io\\.dac'. Если не указ. - возвращает все VSM-параметры
 * @param {string} comments Если указана строка из неё строится выражение для фильтрации comments Ex.: '^soft\\.settings\\.logger\\.active'. Если не указ. - не возвращает комменты к VSM-параметрам
 * @param {string} target строка с идентификаторами через запятую Ex.: 'vsm0,vsm5,vsm2'
 * @return {Promise} resolve({cmd:"total_notify", target:"vsm0", id:"1620979991173.2556", data:["io.dac.8","io.dac.7","io.dac.6","io.dac.5","io.dac.3","io.dac.1","io.dac.2","io.dac.4"], comments:{"soft.settings.logger.active_loggers.comment":"Names of logger instances\u0000"}}) */
WS.onceTotalPromise = async (socket_id, data=undefined, comments=undefined, target=undefined) => new Promise(resolve => {
    const listenCmd = 'total_notify'
    const id = (Date.now()+Math.random()).toFixed(4) //"1620979366042.4858"
    //вначале подписка
    let listener = json => {
        if (id == json.id) {
            WS.off(listenCmd, listener)
            resolve(json)
        }
    }
    WS.on(listenCmd, listener)


    //затем команда
    let cmdObj = {cmd:'total_once', id}
    if ('undefined' != typeof data) cmdObj.data = data
    if ('undefined' != typeof comments) cmdObj.comments = comments
    if ('undefined' != typeof target) cmdObj.target = target
    if ('undefined' != typeof socket_id) cmdObj.socket_id = socket_id
    WS.sendObj(cmdObj)
})


WS.dumpPromise = async (target = undefined, utf8 = true) => new Promise(resolve=>{
    const listenCmd = 'dump_notify'
    const id = (Date.now()+Math.random()).toFixed(4) //"1620979366042.4858"
    //вначале подписка
    let listener = json => {
        if (id == json.id) {
            WS.off(listenCmd, listener)
            resolve(json)
        }
    }
    WS.on(listenCmd, listener)


    //затем команда
    let cmdObj = {cmd:'dump_database', id}
    if (!utf8) cmdObj.utf8 = false
    if ('undefined' != typeof target) cmdObj.target = target
    WS.sendObj(cmdObj)
})

/** Разъединиться с вэбсокет сервером. И по умолчанию вырубить reconnect для исключения автоматического повторного соединения */
WS.disconnect = (reconnect= false)=>{
    try {
        /** @var {WS} wsObj */
        let wsObj = WS.get()
        wsObj.disconnect(reconnect)
    } catch (e) {}
}

/** Заново соединиться с вэбсокет сервером. И по умолчанию установить флаг автоматического повторного соединения в true */
WS.reconnect = (reconnect=true) => {
    try {
        /** @var {WS} wsObj */
        let wsObj = WS.get()
        wsObj.setReconnect(reconnect)
        wsObj.connect(wsObj._url)
    } catch (e) {}
}


//WS.sendData('subscribe', ['io.input.mira.miragateway.tag1.pressure', 'io.input.mira.miragateway.tag1.temperature', 'io.input.mira.miragateway.tag1.humidity'])


//WS.SingletonError = SingletonError


//Аварии

const map = Symbol('map')
class AlarmTranslate {
    /** @param obj Объект с ключами идентификаторами и расшифровками Ex.: {Alarm_Condensate_3_1:'Образование конденсата на климатической балке', Alarm_NoConnect_AHU_1_4:'Нет коммуникации с вентиляционным агрегатом'} */
    constructor(obj={}) {
        this[map] = new Map(Object.entries(obj))
    }

    /** Проверить наличие расшифровки для ключа
     * @param {string|mixed} key проверяемый ключ Ex.: 'Alarm_Condensate_3_1'
     * @return {boolean} true ключ найден, false - не найден */
    has(key){ return this[map].has(key) }

    /** Получить расшифровку по ключу
     * @param {string|mixed} key для кого запрашивается расшифровка Ex.: 'Alarm_Condensate_3_1'
     * @return {string|mixed} Значение расшифровки Ex.: 'Образование конденсата на климатической балке' */
    get(key){ return this[map].get(key) }

    /** Добавить новые расшифровки из объекта
     * @param obj Объект с ключами идентификаторами и расшифровками Ex.: {Alarm_Condensate_3_1:'Образование конденсата на климатической балке', Alarm_NoConnect_AHU_1_4:'Нет коммуникации с вентиляционным агрегатом'} */
    addObject(obj) {
        Object.entries(obj || {}).forEach(
            ([key,value])=>{
                this[map].set(key,value)
            }
        )
    }
    //get map(){ return this[map] }
}


const date = Symbol('date')
const id   = Symbol('id')
const type = Symbol('type')
const value= Symbol('value')
const trans= Symbol('translate')
const wait = Symbol('waiting_flag')
const c0='0'.charCodeAt(0)
const c9='9'.charCodeAt(0)

class Alarm {

    /** Тревога
     * @param {string} str Ex.: '19-05-2021 12:58:28.520 000 2 Alarm_NoConnect_AHU_1_4 1'
     * @throws {Error} Ошибка при распознавании строки лога либо даты */
    constructor(str, translate=undefined) {
        let a = String(str).split(' ') //[ "19-05-2021", "12:58:28.520", "000", "2", "Alarm_NoConnect_AHU_1_4", "1" ]
        if (6 < a.length) throw new Error('Log string format error')

        this[date] = a[0]+' '+a[1]//new Date(da[2], da[1]-1, da[0], ta[0], ta[1], ta[2], ta[3])
        this[type] = a[3] // '2'
        this[id] = a[4] // "Alarm_NoConnect_AHU_1_4"
        this[value] = a[5] // '1'

        this[trans] = translate instanceof AlarmTranslate ? translate : new AlarmTranslate(translate || {})

        this[wait] = a[1].indexOf(':')<0
    }

    /** @param {Object|AlarmTranslate} translate если AlarmTranslate - заменяет собой справочник, если обычный объект то добавляет его ключи и значения к имеющемуся справочнику. Ex.1: translate({a:1,b:2}) - добавить ключи a и b с соответствующими значениями. Ex.2: translate(new AlarmTranslate()) - заменить объект AlarmTranslate на новый, содержащий только ключи a и b с соответствующими значениями */
    translate(translate) {
        if(translate instanceof AlarmTranslate) {
            this[trans] = translate
        } else {
            this[trans].addObject(translate)
        }
    }


    /** @return {string} Дата Ex.: '19-05-2021 12:58:28.520' или Ex.(для ожидаемых): '2174 сек' */
    get date(){ return this[date] }

    /** @return {string} Тип Ex.: '2' */
    get type() { return this[type] }

    /** @return {string} Идентификатор Ex.: "Alarm_NoConnect_AHU_1_4" */
    get id(){ return this[id] }

    /** @return {string} Значение Ex.: "1" */
    get value() { return this[value] }

    /** @return {boolean} Признак того что этот alarm из списка ожидаемых (true) */
    get wait() { return this[wait] }



    //-----  вычисляемые  -------

    /** @return {string} Расшифровка идентификатора или сам идентификатор (если у него нет расшифровки в translate) Ex.: 'Образование конденсата на климатической балке' */
    get name() { return this[trans].has(this.id) ? this[trans].get(this.id) : this.id }

    /** @return {string} Коды ошибок из идентификатора Ex.: '1:4' */
    get code() {
        let result = ''
        let s = this.id // "Alarm_NoConnect_AHU_1_4"
        for (let i = s.length - 1; i >= 0; i--) {
            if ((s[i] != '_') && ((s.charCodeAt(i) < c0) || (s.charCodeAt(i) > c9))) {
                result = s.substring(i + 2)
                break
            }
        }

        //result='1_4' замена подчёркиваний на ':' без использования RegExp
        let idx=0
        while ((idx=result.indexOf('_')) >= 0) {
            result = result.substring(0,idx) + ':' + result.substring(idx+1)
        }
        return result
    }

    /** @return {string} Символ тревоги Ex.:'B' если отсутствует в Alarm.TYPE то вернёт 'x' */
    get typeSymbol() { return ((this.type in Alarm.TYPE) && Alarm.TYPE[this.type].symbol) ? Alarm.TYPE[this.type].symbol : 'x' }

    /** @return {string} Картинка в Svg или пустая строка если отсутствует в Alarm.TYPE Ex.:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><circle cx="32" cy="32" r="32" style="fill:green;"/><text x="32" y="48" style="fill:white; font-size:48px; font-weight:bold; font-family:Arial;" text-anchor="middle">B</text></svg>' если отсуствует в Alarm.TYPE то вернёт '' */
    get typeSvg() { return ((this.type in Alarm.TYPE) && Alarm.TYPE[this.type].svg) ? Alarm.TYPE[this.type].svg : '' }
}

/** Из массива waiting-тревог получить список объектов Alarm
 * @param {Array} a из обработчика on_logger Ex.: ['2','Alarm_FE1belowSetpoint_2_6','7200','2176',   '2','Alarm_FS1belowSetpoint_2_4','7200','2176',   '2','Alarm_TempBelowSetpoint_2_2','7200','2180']
 * @param {Object|AlarmTranslate} translate если AlarmTranslate - установить этот справочник всем. Если Object или undefined - то каждому создать свой пустой справочник
 * @return {Array} Массив объектов Alarm */
Alarm.parseWaiting = (a, translate=undefined)=>{ //['2', 'Alarm_FE1belowSetpoint_2_6', '7200', '2184',   '2', 'Alarm_FS1belowSetpoint_2_4', '7200', '2184']
    let r=[]
    for(let i=0; i<a.length; i+=4) {
        if (a.length<(i+4)) break; // не кратно 4
        r.push(new Alarm(a[i+3]+' сек 000 '+a[i]+' '+a[i+1]+' '+a[i+2], translate))
    }
    return r
}

Alarm.TYPE={
    // '0'=>{symbol:'i', svg: `<svg viewBox="0 0 64 64"><circle cx="32" cy="32" r="32"/><text x="32" y="48">A</text></svg>`,
    // '1'=>{symbol:'A', svg: '..'},
    // '2'=>{symbol:'B', svg: '..'},
    // ...
    // '30'=>{symbol:'B'},
}
Alarm.svg=(content)=>`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">${content}</svg>`
Alarm.svg_sym=(sym,color='white')=>`<text x="32" y="48" style="fill:${color}; font-size:48px; font-weight:bold; font-family:Arial;" text-anchor="middle">${sym}</text>`
Alarm.svg_circle=(color='green')=>`<circle cx="32" cy="32" r="32" style="fill:${color};"/>`
Alarm.svg_triangle=(color='green')=>`<path fill="${color}" d="M 0 54 32 0 64 54 0 54"/>`
for(let i = 0; i<=30; i++) {
    let symbol = (0==i)?'i':((1==i)?'A':'B')
    Alarm.TYPE[i]={
        symbol,
        svg: Alarm.svg(
            ((0==i) ? Alarm.svg_triangle() : Alarm.svg_circle((1==i)?'red':'green' ))
            +
            Alarm.svg_sym(symbol)
        )
    }
}
const EVENT={
    ACTIVE: 'active', //активные тревоги
    WAITING: 'waiting', //ожидаемые тревоги
    HISTORY: 'history', //история тревог
    CURRENT: 'current', //текущий вывод журнала
    TRANSLATE:'translate', //перевод идентификаторов тревог
}

WS.EVENT = EVENT
WS.Alarm = Alarm
WS.AlarmTranslate = AlarmTranslate

module.exports = WS
//export default WS
