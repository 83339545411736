import config from "../../config";
import store from "@/store/index"
const setting = config.server.api.frontend ? 'frontend' : 'backend'
const ssl = config.server.api[setting].ssl ? 'https://' : 'http://'
const port = config.server.api[setting].port ? (':' + config.server.api[setting].port) : ''
const _h = config.server.api[setting].host
const host = ssl + (_h ? _h : location.hostname) + port


export default {
    /**
    * Функция проверки списка тегов на конкретный тег
    * @param  {Array}  access_tags      ["read_page","edit_page","delete_page"]
    * @param  {String} tag   "read_page"
    * @return {Bool}
    * */
    accessTag:(access_tags,tag)=>{
        if (access_tags && tag && access_tags.length){
            const access_tag = access_tags.find(at=>at.tag.name == tag)
            if (access_tag){
                return true
            }
        }
        return false
    },
    /**
    * Функция построение запроса к базе
    * @param  {String} url      URL только ключевые параметры "structure/data", "room/edit"
    * @param  {Object} params   Дополнительные параметры для запроса
    * @return {Object}
    * */
    get: async function (url, params = {}) {
        let login = this.getCookie('login');
        let hash = this.getCookie('hash');
        let body = {
            login: login,
            hash: hash
        }
        if (params) {
            body = {...body, ...params}
        }


        return fetch(host + '/' + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(res => {
                //console.log('--запросы');
                //console.log(host + '/' + url);
                return res.json()
            })
            .catch(() => console.error("Не удалось получить " + url))
    },
    getFile: async function (data) {
        const login = this.getCookie('login');
        const hash = this.getCookie('hash');
        data.append('login', login)
        data.append('hash', hash)
        return fetch(host+'/panorama/add', {
            method: 'POST',
            //origin:'http://localhost:8090',
            //mode: 'no-cors',
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // },
            body: data
        })
            .then(res => {
                return res.json()
            })
            .catch(() => console.error("Не удалось добавить панораму"))
    },
    /**
    * Функция конвертирует данные из одного формата в другой (°F -> °C)
    * @param  {String} value     Значение параметра
    * @param  {Object} param     Объект параметра, полученный из базы
    * @param  {Bool}   reverse   Обратное конвертирование
    * @return {Object | Bool} {value: '1047.6', shortname: 'м³/ч', options: null, is_selector: false} || false
    * */
    formatMeasure: function (value, param, reverse = false) {
        if (param && (value || value == '0')) {
            //удаление лишний расширений
            // if (/[C,S]/.test(value)){
            //     console.log(value)
            //     //value = Number(value.replace(/[a-z]/gi, '')).toFixed(1)
            // }
            if (isNaN(Number(value))){
                const count =  value.match(/[a-zA-Zа-яА-Я]/g)
                //if (count && count.length < 3) value = Number(value.replace(/[a-z]/gi, '')).toFixed(1)
                if (count && count.length <= 2) value = Number(value.replace(/[a-zA-Zа-яА-Я]/g, '')).toFixed(1)
            }
 
            let val = {
                value: value,
                shortname: param.measure_shortname,
                options: null,
                is_selector: false,
                type_show: param.measure_type_show,
            }
            const units = store.state.measure.units
            const m_template = store.state.profile.measure_template_id
            const m_template_items = store.state.measure.template_items //из чего во что переводим
            if (!m_template_items){
                return val;
            }
            const short_t = m_template_items.filter(t => t.template_id == m_template)
            const t = short_t.find(t => t.group_id == param.measure_group_id)
            let unit = units.find(u => u.id == param.measure_id)
            if (unit && unit.is_selector) {
                val.is_selector = true
                val.options = unit.options.split(/[,;]/).map(kv => {
                    let i = kv.split(/[:=]/).map(v => v.trim());
                    return {key: i[0], value: i[1]}
                })
                return val;
            }
            if (param.non_convertable == '0') {
                if (t && (param.measure_id != t.measure_id)) {
                    unit = units.find(u => u.id == t.measure_id) //новые конвертированные units

                    let mea,mea_s;
                    if (reverse){
                        mea = store.state.measure.conversions.filter(mea => mea.dst_measure_id == param.measure_id)
                        mea_s = mea.find(mea => mea.src_measure_id == t.measure_id)
                    }else{
                        mea = store.state.measure.conversions.filter(mea => mea.src_measure_id == param.measure_id)
                        mea_s = mea.find(mea => mea.dst_measure_id == t.measure_id)
                    }
                    if (mea_s) {
                        val.shortname = unit.shortname
                        const e = this.conversion(mea_s.rule, value);
                        if (e || e == 0) {
                            val.value = e
                            return val
                        }
                    }
                }
            }
            return val
        }
        return false
    },
    /**
    * Функция решения математических формул
    * @param  {String} rule            Формула (пример: (x-32)*0.5556)
    * @param  {String | Number} x     Значения x (example: 2,5,-25.4 и тд)
    * @return {String | Bool}          '1045' || false
    * */
    conversion: function (rule, x) {
        let result = '-';
        if (x == 0) return 0
        if ('string' == typeof x) {
            const dot_x = x.replace(/,/g, '.')
            if (dot_x == '-'){
                return dot_x
            }
            const equation = rule.replace(/x/g, dot_x)
            result = eval(equation)
        } else if ('number' == typeof x) {
            const equation = rule.replace(/x/g, x)
            result = eval(equation)
        }
        if (result) {
            return result.toFixed(1)
        }
        return result
    },
    /**
    * Функция запроса файла
    * @param  {String} url      URL только ключевые параметры "structure/data", "room/edit"
    * @param  {Object} params   Дополнительные параметры для запроса
    * @return {Object}
    * */
    postFile: async function (url, params = {}) {
        let login = this.getCookie('login');
        let hash = this.getCookie('hash');
        let body = {
            login: login,
            hash: hash
        }
        if (params) {
            body = {...body, ...params}
        }
        return fetch(host + '/' + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(res => {
                return res.text()
            })
            .catch(() => console.error("Не удалось получить " + url))
    },
    /**
    * Функция авторизации
    * @param  {String} login
    * @param  {String} password
    * @return {Bool}
    * */
    Auth: async function (login, password) {
        return fetch(host + '/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login: login,
                password: password
            })
        }).then((response) => {
            return response.json()
        })
    },
    /*
    * Функция проверки текущего пользователя
    * @param  {String} login
    * @param  {String} hash
    * @return {Bool}
    * */
    checkAuth: async function (login, hash) {
        return fetch(host + '/check/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login: login,
                hash: hash
            })
        }).then((response) => {
            return response.json()
        })
    },
    /**
    * Функция записи cookie
    * @param  {String} name
    * @param  {String} value
    * @param  {Number} days
    * */
    setCookie: function (name, value, mins) {
        let expires = "";
        if (mins) {
            const date = new Date();
            date.setTime(date.getTime() + (mins * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    /**
    * Функция получение cookie
    * @param  {String} name
    * @return {String}
    * */
    getCookie: function (name) {
        // const nameEQ = name + "=";
        // const ca = document.cookie.split(';');
        // for (let i = 0; i < ca.length; i++) {
        //     let c = ca[i];
        //     while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        //     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        // }
        // return null;
        const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    /**
    * Функция удаления cookie
    * @param  {String} name
    * */
    eraseCookie: function (name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    /**
    * Функция перевода даты в формат ISO
    * @param  {String} textDate     10-09-2021 11:04:29.884
    * @return {String} Y,m,d,h,m,s,ms
    * */
    parseDate:function(textDate) {
        var re=/([0-9]{2})-([0-9]{2})-([0-9]{4}) ([0-9]{2}):([0-9]{2})(:([0-9]{2}))?/;
        if (!re.test(textDate)){
            //console.error("Not valid string '"+textDate+"' as Date (DD-MM-YYYY HH:mm:ss)");
            return new Date()
        }
        var t,a = textDate.match(re); //"08.05.2013 15:41:00" -> ["08.05.2013 15:41:00", "08", "05", "2013", "15", "41", ":00", "00"]
        return new Date(parseInt(a[3],10), parseInt(a[2],10)-1, parseInt(a[1],10), parseInt(a[4],10), parseInt(a[5],10), isNaN(t=parseInt(a[7],10))?0:t, 0)//Y,m,d,h,m,s,ms
    },
    /**
     * Функция закрашивания индикатора на плане
     * @param  {Node} item          path
     * @param  {Number} min         -20
     * @param  {Number} max         30
     * @param  {Number} current     15
     * @param  {Object} c1          {r:255,g:0,b:0,1}
     * @param  {Object} c2          {r:0,g:0,b:255,1}
     * */
    paintOver(item, min, max, current, c1, c2){
        const average = (min + max)/2 //находит среднее значение  15
        let steps;
        if (average >=current) {
            steps = Math.abs(current - min) //сколько чисел нужно пройти
        }else{
            steps = Math.abs(max - current) //сколько чисел нужно пройти
        }
        const maxSteps = max - average //сколько всего чисел для одного цвета
        if (current > max) {
            item.style.fill = 'rgba('+c2.r+','+c2.g+','+c2.b+','+c2.a+')';
        }else if(min > current){
            item.style.fill = 'rgba('+c1.r+','+c1.g+','+c1.b+','+c1.a+')';
        }else if (average >=current) {
            const proportions = {
                r:(255 - c1.r) / maxSteps,
                g:(255 - c1.g) / maxSteps,
                b:(255 - c1.b) / maxSteps
            }
            item.style.fill = 'rgba('+(c1.r+(proportions.r*(steps-1)))+','+(c1.g+(proportions.g*(steps-1)))+','+(c1.b+(proportions.b*(steps-1)))+','+c1.a+')';
        }else{
            const proportions = {
                r:(255 - c2.r) / maxSteps,
                g:(255 - c2.g) / maxSteps,
                b:(255 - c2.b) / maxSteps
            }
            item.style.fill = 'rgba('+(c2.r+(proportions.r*steps))+','+(c2.g+(proportions.g*steps))+','+(c2.b+(proportions.b*steps))+','+c2.a+')';
        }
    },
    paintVarietyIndicator(el,val,variety,opacity){
        el.style.opacity = opacity
        el.style.fill = this.getColor(val, variety);
        //console.log(el,variety)
    },
    getColor(val, mapColor) {
        // let result = mapColor[0].color, i = 0
        // const stepT = 0.5 / 2
        // for(let t of mapColor) {
        //     i++
        //     let thresholdPlus = Number(t.value) + stepT // 21.5 + 0.25 = 21.75
        //     let thresholdMinus= Number(t.value) - stepT // 21.5 - 0.25 = 21.25
        //     if ((i == 1) && (val <= thresholdPlus)) {result = t.color; break } //до 21.75 включительно - самый холодный
        //     else if ((i == t.value) && (val>=thresholdMinus)) {result = t.color; break } //24.25 и выше - самый теплый
        //     else if ((val >= thresholdMinus) && (val <= thresholdPlus)) {result = t.color}
        // }
        let colors =  mapColor.sort(function(a, b) {
            return parseFloat(a.value) - parseFloat(b.value);
        });
        let result = colors[0].color
        for(let t of colors){
            if (parseFloat(val) >= parseFloat(t.value)) {
                result = t.color
            }
        }
        return result
    },
    filterDC(dc, params, level, ds) {
        const ddc = dc.filter(row => {
            let check = true;
            if (!row.v_table && row.access_mask[level] !== '-') {
                if (row.condition) {
                    this.parseCondition(row.condition).forEach(con => {
                        const current = params.find(param => (param.paramname === con.name1) && param.connection === ds)
                        if (current) {
                            switch (con.operation) {
                                case '<=':
                                    if(check) check = current.value <= con.name2
                                    break;
                                case '>=':
                                    if(check) check = current.value >= con.name2
                                    break;
                                case '!=':
                                    if(check) check = current.value != con.name2
                                    break;
                                case '>':
                                    if(check) check = current.value > con.name2
                                    break;
                                case '<':
                                    if(check) check = current.value < con.name2
                                    break;
                                case '=':
                                    if(check) check = current.value == con.name2
                                    break;
                            }
                        }
                    })
                }
            }
            return check;
        })
        return ddc.sort(function (a, b) {
            return a.sort - b.sort;
        })
    },
    parseCondition(s){
        let arrCondition = s.replaceAll(' ','').split(';')
        return arrCondition.map(cond=>{
            let operation = ''
            switch (true) {
                case cond.includes('<='):
                    operation = '<='
                    break;
                case cond.includes('>='):
                    operation = '>='
                    break;
                case cond.includes('!='):
                    operation = '!='
                    break;
                case cond.includes('>'):
                    operation = '>'
                    break;
                case cond.includes('<'):
                    operation = '<'
                    break;
                case cond.includes('='):
                    operation = '='
                    break;
            }
            const splits = cond.split(operation)
            return {
                name1:splits[0],
                operation:operation,
                name2:splits[1],
            }
        })
    },
    getRuDate(date, hours){
        var options = {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        let now = new Date(date)
        if (hours){
            now = new Date(now.setHours(now.getHours() + hours))
        }
        return now.toLocaleDateString("ru", options);
    },
}