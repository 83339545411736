import helper from "@/store/helper";
import pack from "../../package.json";
// import router from "@/router/index";
// import WS from '@/vsm/wsClient'
export default {
    TITLE(state,title){
        state.options.title = title
        document.title = title;
    },
    OPTIONS_SELECT(state,data){
        if (data){
            if (typeof data.object !== 'undefined'){
                helper.setCookie('object', data.object,43200)
                state.options.current.object = data.object
            }
            if (typeof  data.build !== 'undefined'){
                helper.setCookie('build', data.build,43200)
                state.options.current.build = data.build
            }
        }
    },
    NOTI_REMOVE(state,data){
        let noti = state.notifications.find(n=>n.id == data.id)
        let index = state.notifications.indexOf(noti)
        if (noti){
            if (noti.settime){
                clearTimeout(noti.settime)
            }
            state.notifications.splice(index,1)
        }
    },
    NOTI_CREATE(state,data){
        let noti = {
            id: Date.now() + Math.random(),
            title: data.title?data.title:'',
            text: data.text?data.text:'',
            type: data.type?data.type:2,
            time: data.time?data.time:7000,
            settime:null
        }

        if (noti.time != -1){
            noti.settime = setTimeout(()=>{
                this.commit('NOTI_REMOVE',noti)
            },noti.time)
        }
        //Vue.set(state.notifications, state.notifications.length, noti)
        state.notifications.push(noti)

    },
    PARAMS_CLEAR(state,priority){
        if (priority){
            state.params = state.params.filter(p=>p.priority < priority)
        }else{
            state.params = []
        }
    },
    PARAM_UPDATE(state,data){
        const conn = state.connections.find(c=>c.target == data.target)
        if(conn){
            const params = state.params.filter(param=>param.paramname == data.param)
            const param = params.find(param=>param.connection == conn.id)
            if (param){
                param.value = data.value
            }
        }
    },
    PARAMS_ADD(state,data){
        if(data.length){
            data.forEach(function (param) {
                const params = state.params.filter(p=>p.paramname == param.paramname)
                if (params.length){
                    const checkCon = params.find(i => i.connection == param.connection)
                    if (!checkCon){
                        state.params.push(param)
                    }
                }else{
                    state.params.push(param)
                }
            })
        }
    },
    LOG_OUT(){
        helper.eraseCookie('login')
        helper.eraseCookie('hash')
        document.location.reload()
    },
    PROFILE_UPDATE(state,data){
        if (data){
            //console.log(state.profile.client, data.access_tags)
            if (data.client) state.profile.client = data.client
            if (data.email) state.profile.email = data.email
            if (data.fullname) state.profile.fullname = data.fullname
            if (data.hash) state.profile.hash = data.hash
            if (data.id) state.profile.id = data.id
            if (data.login) state.profile.username = data.login
            if (data.measure_template_id) state.profile.measure_template_id = data.measure_template_id
            if (data.role) state.profile.role_id = data.role
            if (data.level) state.profile.level = data.level
            if (data.access_tags) state.profile.access_tags = data.access_tags
        }
    },
    WS_CLIENT(state,data){
        if (data){
            state.ws = data
        }
    },
    CLEAR_ALERTS(state,target){
        state.alerts.active = state.alerts.active.filter(item => target !== item.target);
        state.alerts.waiting = state.alerts.waiting.filter(item => target !== item.target);
        state.alerts.history = state.alerts.history.filter(item => target !== item.target);
    },
    ALERTS_CONNECTIONS_CLEAR(state){
        state.alerts.active = []
        state.alerts.waiting = []
        state.alerts.history = []
        state.alerts.translate = {}
    },
    CONNECTIONS_UPDATE(state,data){
        if(data.length){
            let remove = [];
            data.forEach(connect =>{
                let current = state.connections.find(c=> c.id == connect.id)
                if (current){
                    if (!connect.enable && current.enable){
                        this.commit('CLEAR_ALERTS', current.target)
                    }
                    current.addr = connect.addr
                    current.created = connect.created
                    current.description = connect.description
                    current.enable = connect.enable
                    current.id = connect.id
                    current.image = connect.image
                    current.name = connect.name
                    if (connect.socket_id) current.socket_id = connect.socket_id
                    //current.socket_id = connect.socket_id
                    current.status = connect.status
                    current.target = connect.target
                    current.type = connect.type
                }else{
                    state.connections.push(connect)
                }
            })
            if(state.connections.length > data.length){
                state.connections.forEach((connect, idx) => {
                    let current = data.find(c => c.id == connect.id)
                    if (!current){
                        this.commit('CLEAR_ALERTS', connect.target)
                        remove.push(idx)
                    }
                })
                remove.sort().reverse().forEach(idx=>{
                    state.connections.splice(idx,1)
                })
            }
        }
    },
    CONNECT_UPDATE(state,connect){
        if(connect){
            let current = state.connections.find(c=> c.id == connect.id)
            if (current){
                current.addr = connect.addr
                current.created = connect.created
                current.description = connect.description
                current.enable = connect.enable
                current.id = connect.id
                current.image = connect.image
                current.name = connect.name
                current.socket_id = connect.socket_id
                //current.socket_id = connect.socket_id
                current.status = connect.status
                current.target = connect.target
                current.type = connect.type
            }else{
                state.connections.push(connect)
            }
        }
    },
    CONNECTION_UPDATE(state,data){
        if(data){
            let connects = state.connections.filter(item=> item.target == data.id)
            if (connects){
                connects.forEach((connect)=>{
                    connect.status = data.connected
                    if (connect.status){
                        connect.target = data.id
                    }else{
                        this.commit('NOTI_CREATE', {type: 2, time: 5000, text: "Нет соединения с "+connect.name+"."})
                        connect.target = ''
                    }
                })
            }
        }
    },
    WS_DISCONNECT(state){
        state.connections.forEach((connect)=>{
            connect.status = false
            connect.target = ''
        })
    },
    UPDATE_ALERT_TRANSLATE:(state,data)=>{
        state.alerts.translate = {...state.alerts.translate,...data.translate}
        state.alerts.active.filter(alert => {
            let x =  Object.keys(state.alerts.translate).includes(alert.name)
            if (x) alert.translate = state.alerts.translate[alert.name]
        })
        state.alerts.waiting.filter(alert => {
            let x =  Object.keys(state.alerts.translate).includes(alert.name)
            if (x) alert.translate = state.alerts.translate[alert.name]
        })
        state.alerts.history.filter(alert => {
            let x =  Object.keys(state.alerts.translate).includes(alert.name)
            if (x) alert.translate = state.alerts.translate[alert.name]
        })
    },
    DELETE_ALERTS_CONNECT:(state, data)=>{
        state.alerts.active = state.alerts.active.filter(alert=> alert.target !== data.target)
        state.alerts.waiting = state.alerts.waiting.filter(alert=> alert.target !== data.target)
        state.alerts.history = state.alerts.history.filter(alert=> alert.target !== data.target)
    },
    ALERTS_UPDATE: (state, data) => {
        state.alerts[data.type] = data.alerts
    },
    ALERTS_LOG_UPDATE: (state, data) => {
        state.alerts[data.type] = [...state.alerts[data.type].filter(a=> a.log !== data.log || a.connect != data.connect),...data.data]
    },
    CREATE_ALERT: (state, data) => {
        let alert = state.alerts.find(alert => alert.name === data.name && alert.target === data.target)
        if (!alert){
            state.alerts.push({
                name:data.name,
                log_type: data.log_type,
                target:data.target,
                current:[],
                active:[],
                waiting:[],
                history:[]
            })
        }
    },
}
