/* eslint-disable */
//import Vue from 'vue';
import store from '@/store/index';
//import VueRouter from 'vue-router';
import { createWebHashHistory, createRouter, createWebHistory } from 'vue-router';
import helper from "@/store/helper";
import config from "@/../config";

const Auth          = () => import("@/views/pages/Auth.vue");
const Panel         = () => import("@/views/pages/Panel.vue");
const Screens       = () => import("@/views/pages/Screens.vue");
const Structure     = () => import("@/views/pages/Structure.vue");
const InnerPage     = () => import("@/views/pages/Structure/InnerPage.vue");
const Scheme        = () => import("@/views/pages/Scheme.vue");
const Explore       = () => import("@/views/pages/Explore.vue");
const Collector     = () => import("@/views/pages/Collector.vue");
const Alerts        = () => import("@/views/pages/Alerts.vue");
const Users         = () => import("@/views/pages/Users.vue");
const Roles         = () => import("@/views/pages/Roles.vue");
const Panoramas     = () => import("@/views/pages/Panoramas.vue");
const Instruction   = () => import("@/views/pages/Instruction.vue");
const Connections   = () => import("@/views/pages/Connections.vue");
const Logs          = () => import("@/views/pages/Logs.vue");
const Setting       = () => import("@/views/pages/Setting.vue");
const page404       = () => import("@/views/pages/404.vue");

//Vue.use(VueRouter)

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        meta: {
            auth: false,
            layout: 'full',
            title: 'Авторизация'
        },
        component: Auth
    },
    {
        path: '/',
        redirect: '/panel'
    },
    // {
    //     path: '/screens',
    //     redirect: '/structure'
    // },
    {
        path: '/panel',
        name: 'Panel',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Графики',
            options:{
                add_to_menu: true,
                link_title: 'Графики',
                icon: 'status',
                access_tag: 'view_page_panel',
            },
        },
        component: Panel
    },
    {
        path: '/screens',
        name: 'Screens',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Экраны',
        },
        component: Screens
    },
    {
        path: '/structure',
        name: 'Structure',
        meta: {
            title: 'Структура',
            options:{
                add_to_menu: true,
                link_title: 'Структура',
                icon: 'sitemap',
                access_tag: 'view_page_structure',
            },
            auth: true,
            layout: 'default',
        },
        component: Structure
    },
    {
        path: '/structure/ahu/:ahu',
        name: 'Ahu',
        meta: {
            auth: true,
            layout: 'default',
            key: 'ahu',
            title: 'Агрегат'
        },
        component: InnerPage
    },
    {
        path: '/structure/zone/:zone',
        name: 'Zone',
        meta: {
            auth: true,
            layout: 'default',
            key: 'zone',
            title: 'Зона'
        },
        component: InnerPage
    },
    {
        path: '/structure/room/:room',
        name: 'Room',
        meta: {
            auth: true,
            layout: 'default',
            key: 'room',
            title: 'Помещение'
        },
        component: InnerPage
    },
    {
        path: '/structure/product/:product',
        name: 'Product',
        meta: {
            auth: true,
            layout: 'default',
            key: 'product',
            title: 'Продукт'
        },
        component: InnerPage
    },
    {
        path: '/scheme',
        name: 'Scheme',
        meta: {
            title: 'Схемы',
            options:{
                add_to_menu: true,
                link_title: 'Схемы',
                icon: 'drafting-compass',
                access_tag: 'view_page_scheme',
                object: store?store.state.options.current.object:''
            },
            auth: true,
            layout: 'default',
        },
        component: Scheme,
    },
    {
        path: '/alerts',
        name: 'Alerts',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Тревоги',
            options:{
                add_to_menu: true,
                link_title: 'Тревоги',
                icon: 'exclamation-triangle',
                access_tag: 'view_page_alerts',
            },
        },
        component: Alerts
    },
    {
        path: '/users',
        name: 'Users',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Пользователи',
            options:{
                add_to_menu: true,
                link_title: 'Пользователи',
                icon: 'users',
                access_tag: 'can_list_users',
            },
        },
        component: Users,
    },
    {
        path: '/roles',
        name: 'Roles',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Роли пользователей',
            options:{
                add_to_menu: true,
                link_title: 'Роли',
                icon: 'user-lock',
                access_tag: 'can_list_roles',
            },
        },
        component: Roles
    },
    {
        path: '/panoramas',
        name: 'Panoramas',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Панорамы',
            options:{
                add_to_menu: true,
                link_title: 'Панорамы',
                icon: 'panorama',
                access_tag: 'view_page_panoramas',
            },
        },
        component: Panoramas
    },
    {
        path: '/instruction',
        name: 'Instruction',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Инструкция',
        },
        component: Instruction
    },
    {
        path: '/connections',
        name: 'Connections',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Источники данных',
            options:{
                add_to_menu: true,
                link_title: 'Источники данных',
                icon: 'link',
                access_tag: 'view_page_connections',
            },
        },
        component: Connections
    },
    {
        path: '/logs',
        name: 'Logs',
        meta: {
            auth: true,
            layout: 'default',
            title: 'Журнал',
            options:{
                add_to_menu: true,
                link_title: 'Журнал',
                icon: 'clipboard-list',
                access_tag: 'view_page_logs',
            },
        },
        component: Logs
    },
    {
        path: '/explore',
        name: 'Explore',
        meta: {
            title: 'Интерактивные графики',
            options:{
                add_to_menu: true,
                //parent:'Setting',
                link_title: 'Интерактивные графики',
                icon: 'chart',
                access_tag: 'view_page_explore',
            },
            auth: true,
            layout: 'default',
        },
        component: Explore
    },
    {
        path: '/collector',
        name: 'Collector',
        meta: {
            title: 'Сбор данных',
            options:{
                add_to_menu: true,
                link_title: 'Сбор данных',
                icon: 'collector',
                access_tag: 'view_page_collector',
            },
            auth: true,
            layout: 'default',
        },
        component: Collector
    },
    {
        path: '/setting',
        name: 'Setting',
        meta: {
            title: 'Настройки',
            options:{
                add_to_menu: true,
                link_title: 'Настройки',
                icon: 'gears',
                access_tag: 'view_page_setting',
            },
            auth: true,
            layout: 'default',
        },
        component: Setting
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
]
const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory(),
    routes
})


//Импорт маршрутов проекта
const RouterProject = ()=>{
    try {
        return import('@/projects/' + config.project.path + '/router/index')
    }catch(e){
        return false
    }
}
//Добавление маршрутов к общему списку
RouterProject().then(response=>{
    if (response && response.default && response.default.routes && response.default.routes.length){
        response.default.routes.forEach(route=>{
            router.addRoute(route)
        })
    }
})

// router.afterEach((to, from) => {
//     console.log(to.meta.options.access_tag, store.state.profile)
// })
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) { // нужна ли авторизация для страницы
        const login = helper.getCookie('login');
        const hash = helper.getCookie('hash');
        if (login && hash) { //проверка куки
            if (store.state.ws.server){
                store.state.ws.server.send(
                    JSON.stringify({
                        cmd: 'checkAuth',
                        pass: {
                            login: login,
                            hash: hash
                        }
                    })
                );
            }
        }else{
            next('auth')
        }
    }
    next()
})
export default router
