/* eslint-disable */
import helper from "@/store/helper";
import config from "../../config";
import WS from '@/vsm/wsClient'
import Vue from 'vue'
import router from "@/router";

export default {
    WS_PARAM_UPDATE: (context, data) => {
        let connect = context.state.connections.find(c => c.id == data.connection)
        if (connect && connect.status) {
            context.state.ws.server.send(JSON.stringify({
                cmd: "set",
                target: connect.target,
                data: {[data.paramname]: data.value}
            }))
            helper.get('set/log/add', {
                user_id: context.state.profile.id,
                username: context.state.profile.username,
                ds_id: data.connection,
                param: data.paramname,
                value: data.value
            })
        }
    },
    WS_ONCE_TOTAL:(context,data)=>{
        console.log(data)
    },
    WS_GET_PARAMS: (context) => {
        context.state.connections.forEach(function (connect) {
            //console.log(connect.socket_id, connect.target)
            if (connect.status && connect.target && connect.socket_id) {
                context.state.ws.server.send(JSON.stringify({
                        cmd: "unsubscribeExcept",
                        target: connect.target,
                        socket_id: connect.socket_id,
                        data: []
                    }
                ))
                const params = context.state.params.filter(param => param.connection == connect.id)
                const req = params.map(param => param.paramname)
                if (req.length) {
                    context.state.ws.server.send(JSON.stringify({
                        cmd: "dispatch",
                        data: "100",
                        target: connect.target,
                        timeout: "3000"
                    }))
                    context.state.ws.server.send(JSON.stringify({
                        cmd: "subscribe",
                        target: connect.target,
                        socket_id: connect.socket_id,
                        data: req
                    }))
                }
            }
        })
    },
    WS_DELETE_CONNECT: async (context, data) => {
        await helper.get('remove/connection', data)
    },
    WS_AUTH: (context, pass) => {//Авторизация
        if(context.state.ws.server){
            context.state.ws.server.send(JSON.stringify({cmd: 'auth', pass: pass}));
        }
    },
    WS_CONNECT: async (context) => {
        const measure = await helper.get('measure')
        if (measure && Object.keys(measure).length) {
            //Vue.set(context.state, 'measure', measure)
            context.state.measure = measure
        }
        const setting = config.server.ws.frontend ? 'frontend' : 'backend'
        const ssl = config.server.ws[setting].ssl ? 'wss://' : 'ws://'
        const port = config.server.ws[setting].port ? (':' + config.server.ws[setting].port) : ''
        const h = config.server.ws[setting].host
        const host = ssl + (h ? h : location.hostname) + port

        // const port = config.server.ws.prod || (':' + config.server.ws.port)
        const ws = new WebSocket(host);
        ws.onopen = function () {
            context.state.ws.status = true
            context.state.ws.server = ws
            ws.send(JSON.stringify({cmd: 'connections'}));
        };
        let s = 0
        if (config.project.indicator_ws) {
            setInterval(()=>{
                context.state.ws.volume = s
                s = 0
            },1000)
        }
        ws.onmessage = (message) => {
            if (config.project.indicator_ws) {
                s++
            }
            if (message.data) {
                const json = JSON.parse(message.data);
                switch (json.cmd) {
                    case 'notify_connect_vsm':
                        ws.send(JSON.stringify({cmd: 'connections'}));
                        setTimeout(()=>{
                            context.dispatch('WS_GET_PARAMS')
                        },5000)
                        break
                    case 'notify_disconnect_vsm':
                        //context.commit('PARAMS_CLEAR')
                        break
                    case 'users':
                        if (json.data) {
                            context.state.ws.temporaryMessage = json
                        }
                        break
                    case 'kick':
                        helper.eraseCookie('login')
                        helper.eraseCookie('hash')
                        document.location.reload()
                        break
                    case 'auth':
                        if (json && json.data && json.data.status) {
                            context.commit('PROFILE_UPDATE', json.data)
                            helper.setCookie('login', json.data.login)
                            helper.setCookie('hash', json.data.hash)
                            router.push({name: 'Scheme'})
                        } else {
                            context.commit('NOTI_CREATE', {type: 1, time: 5000, text: "Неверное имя пользователя или пароль.", title: 'Авторизация'})
                        }
                        break
                    case 'checkAuth':
                        if (json && json.data && json.data.status) {
                            context.commit('PROFILE_UPDATE', json.data)
                        } else {
                            if (router && router.currentRoute.value.name !== 'Auth') router.push({name: 'Auth'})
                        }
                        break
                    case 'who':
                        if (json && json.client) {
                            //Vue.set(context.state.profile, 'client', json.client)
                            context.state.profile.client = json.client
                        }
                        const login = helper.getCookie('login');
                        const hash = helper.getCookie('hash');
                        if (login && hash) { //проверка куки
                            ws.send(JSON.stringify({cmd: 'checkAuth', pass: {
                                login: login,
                                hash: hash
                            }}));
                        }
                        break
                    case 'identifier':
                        if (json && json.client) {
                            //Vue.set(context.state.profile, 'client', json.client)
                            context.state.profile.client = json.client
                        }
                        break
                    case 'connection':
                        //console.log('connection')
                        context.commit('CONNECT_UPDATE', json.data)
                        if (json.data && json.data.status) {
                            context.commit('CLEAR_ALERTS', json.data.target)
                            context.dispatch('WS_GET_PARAMS')
                        }
                        break
                    case 'connections':
                        //console.log('connections')
                        if (json.data.length) {
                            context.commit('CONNECTIONS_UPDATE', json.data)
                            //console.log(json.data)
                            context.commit('ALERTS_CONNECTIONS_CLEAR')
                            context.dispatch('WS_GET_PARAMS')
                            ws.send(JSON.stringify({cmd: 'alertsAll'}));
                        }
                        break
                    case 'update_connections':
                        //console.log('update_connections')
                        if (json.data.length) {
                            context.commit('CONNECTIONS_UPDATE', json.data)
                        }
                        break
                    case 'alerts_log_update':
                        context.commit('ALERTS_LOG_UPDATE', json)
                        break
                    case 'alertsAll':
                        Object.keys(json.data).forEach(type=>{
                            context.commit('ALERTS_UPDATE', {
                                type: type,
                                alerts: json.data[type]
                            })
                        })
                        break
                    case 'notify':
                        if (json && "target" in json) {
                            //console.log(Object.keys(json.data).length)
                            Object.keys(json.data).forEach(nv => {
                                context.commit('PARAM_UPDATE', {
                                    param: nv,
                                    value: json.data[nv],
                                    target: json.target,
                                })
                            })
                        }
                        break
                    default:
                        if (config.debug) console.log('default', json)
                }

            }
        };
        ws.onclose = () => {
            context.state.ws.status = false
            if (config.debug) console.log('Reconnecting')
            setTimeout(() => {
                context.dispatch('WS_CONNECT')
            }, 5000)
        }
    },
}

